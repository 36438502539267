html {
  line-height: 1;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
main {
  display: block;
}
hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: rgba(0, 0, 0, 0);
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp,
pre {
  padding: 0.4em;
  font-family: monospace, monospace;
  font-size: 100%;
  background-color: var(--cr-subtle);
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted;
}
fieldset {
  padding: 0;
}
legend {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  position: relative;
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
}
h1:empty,
h2:empty,
h3:empty,
h4:empty,
h5:empty,
h6:empty,
p:empty,
ol:empty,
ul:empty {
  display: none;
}
a {
  position: relative;
  word-wrap: break-word;
  text-decoration: none;
  color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
}
img,
figure,
picture,
video,
canvas,
svg,
audio,
iframe,
embed,
object {
  display: block;
}
img,
figure,
picture,
video,
canvas {
  max-width: 100%;
  height: auto;
  border: none;
  outline: none;
  margin: 0;
}
input,
select,
optgroup,
button,
textarea {
  display: block;
  padding: 0;
  text-transform: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
button,
input[type="submit"] {
  cursor: pointer;
}
textarea {
  resize: vertical;
}
table {
  border-collapse: collapse;
}
:-moz-focusring {
  outline: auto;
}
::-webkit-input-placeholder {
  color: inherit;
}
::-moz-placeholder {
  color: inherit;
}
:-ms-input-placeholder {
  color: inherit;
}
::-ms-input-placeholder {
  color: inherit;
}
::placeholder {
  color: inherit;
}
::-webkit-input-placeholder {
  color: inherit;
}
:-moz-placeholder {
  color: inherit;
}
:-ms-input-placeholder {
  color: inherit;
}
@media (prefers-reduced-motion: reduce) {
  body {
    scroll-behavior: auto;
  }
}
@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-Variable.woff2") format("woff2");
}
:root {
  --space-1: 20px;
  --space-2: 60px;
  --space-3: 200px;
  --cr-warm-1: #ff9160;
  --cr-warm-2: #daaf50;
  --cr-warm-3: #f1ce5f;
  --cr-warm-4: #ff6d01;
  --cr-cold-1: #194147;
  --cr-cold-2: #2c929e;
  --cr-cold-3: #43b7c4;
  --cr-white-1: #ffffff;
  --cr-black-1: #111111;
  --cr-black-2: #031926;
  --border-radius-1: 7px;
  --border-radius-2: 10px;
  --border-radius-3: 15px;
}
@media screen and (max-width: 1024px) {
  :root {
    --space-2: 40px;
    --space-3: 120px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --space-1: 10px;
    --space-3: 60px;
  }
}
html {
  padding: 0.1px 0;
  font-family: "Satoshi", sans-serif;
  color: var(--cr-black-2);
  background-color: var(--cr-white-2);
  scroll-behavior: smooth;
}
.heading1 {
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 900;
  font-size: 80px;
  line-height: 1;
}
.heading2 {
  font: 400 72px/1 "Satoshi", sans-serif;
  letter-spacing: -0.02em;
}
h1,
h2,
h3,
h4,
h5,
h6,
.heading3 {
  text-wrap: balance;
  font: 700 40px/0.9 "Satoshi", sans-serif;
  letter-spacing: -0.02em;
}
.paragraph1 {
  font-size: 36px;
  font-weight: 400;
}
p,
ol,
ul,
.paragraph2 {
  text-wrap: balance;
  font: 400 24px/1.25 "Satoshi", sans-serif;
}
@media screen and (max-width: 1024px) {
  .heading1 {
    font-size: 60px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .heading2 {
    font-size: 36px;
  }
  .paragraph1 {
    font-size: 30px;
  }
  p,
  ol,
  ul,
  .paragraph2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 600px) {
  .heading1 {
    font-size: 28px;
  }
  .heading2 {
    font-size: 27px;
    line-height: 1.2;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .heading3 {
    font-size: 24px;
  }
  .paragraph1 {
    font-size: 24px;
  }
  p,
  ol,
  ul,
  .paragraph2 {
    font-size: 20px;
  }
}
.content {
  width: 95%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}
.content-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 20px;
}
.gap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: var(--space-1);
}
.btn,
.btn-primary {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 20px;
  line-height: 40px;
  font-weight: 600;
  height: 40px;
  white-space: nowrap;
  border-radius: var(--border-radius-1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.btn {
  color: var(--cr-white-2);
  background: var(--cr-black-2);
}
@media (hover: hover) {
  .btn:hover {
    background: var(--cr-cold-2);
  }
}
.btn-primary {
  color: var(--cr-white-1);
  background: var(--cr-warm-4);
}
@media (hover: hover) {
  .btn-primary:hover {
    background: var(--cr-warm-1);
  }
}
.pill {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 6px 16px;
  border: 1px solid;
  border-radius: 20px;
}
.click {
  --size: 60px;
  position: fixed;
  -webkit-transform-origin: center;
  transform-origin: center;
  width: var(--size);
  height: var(--size);
  z-index: 100;
  pointer-events: none;
}
@-webkit-keyframes burst {
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(300%) scale(0.5);
    transform: translateY(300%) scale(0.5);
  }
}
@keyframes burst {
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(300%) scale(0.5);
    transform: translateY(300%) scale(0.5);
  }
}
.click .emoji {
  width: var(--size);
  height: var(--size);
  font-size: var(--size);
  -webkit-animation: calc(0.6s + 0.1s * var(--delay)) burst
    cubic-bezier(0.25, 1, 0.5, 1) both;
  animation: calc(0.6s + 0.1s * var(--delay)) burst
    cubic-bezier(0.25, 1, 0.5, 1) both;
}
.shapes {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  background-color: inherit;
}
.shapes-1,
.shapes-2 {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  aspect-ratio: 1;
  border: 1px solid rgba(125, 125, 125, 0.1);
}
.shapes-1 {
  width: 80%;
  background-color: rgba(125, 125, 125, 0.1);
  overflow: hidden;
}
.shapes-2 {
  width: 55%;
  background-color: inherit;
}
.shapes[data-shape="circle"] .shapes-1,
.shapes[data-shape="circle"] .shapes-2 {
  border-radius: 100%;
}
.shapes[data-shape="square"] .shapes-1,
.shapes[data-shape="square"] .shapes-2 {
  -webkit-transform: translate(-50%, -50%) scale(0.9);
  transform: translate(-50%, -50%) scale(0.9);
}
.shapes[data-shape="rhombus"] .shapes-1,
.shapes[data-shape="rhombus"] .shapes-2 {
  -webkit-transform: translate(-50%, -50%) skew(-10deg, 0deg) scale(0.9);
  transform: translate(-50%, -50%) skew(-10deg, 0deg) scale(0.9);
}
.shapes[data-shape="rhombus-opp"] .shapes-1,
.shapes[data-shape="rhombus-opp"] .shapes-2 {
  -webkit-transform: translate(-50%, -50%) skew(10deg, 0deg) scale(0.9);
  transform: translate(-50%, -50%) skew(10deg, 0deg) scale(0.9);
}
.header,
.mobile-cta {
  top: 0;
  gap: var(--space-1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px var(--space-1);
  color: var(--cr-black-1);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-bottom: 1px solid rgba(125, 125, 125, 0.2);
  z-index: 100;
}
.header__logo,
.mobile-cta__logo {
  margin-right: auto;
}
.header__logo svg,
.mobile-cta__logo svg {
  display: block;
  height: 40px;
  aspect-ratio: 1;
}
.header__links,
.mobile-cta__links {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
.header__links ul,
.mobile-cta__links ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  gap: var(--space-1);
  font-weight: 500;
  font-size: 20px;
}
.header__links ul li a,
.mobile-cta__links ul li a {
  -webkit-transition: color 0.2s, background 0.2s;
  transition: color 0.2s, background 0.2s;
}
@media (hover: hover) {
  .header__links ul li a:hover,
  .mobile-cta__links ul li a:hover {
    color: var(--cr-warm-4);
  }
}
.header__social,
.mobile-cta__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
}
.header__social svg,
.mobile-cta__social svg {
  display: block;
  height: 30px;
  aspect-ratio: 1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
@media (hover: hover) {
  .header__social svg:hover,
  .mobile-cta__social svg:hover {
    color: var(--cr-warm-1);
  }
}
.header__mobile-toggle,
.mobile-cta__mobile-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 12px;
  padding-right: 10px;
  height: 30px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  color: var(--cr-white-1);
  background-color: var(--cr-black-1);
  border-radius: var(--border-radius-2);
}
.header__mobile-toggle__icon,
.mobile-cta__mobile-toggle__icon {
  position: relative;
  display: inline-block;
  width: 15px;
  margin-right: 5px;
  aspect-ratio: 1;
}
.header__mobile-toggle__icon:before,
.header__mobile-toggle__icon:after,
.mobile-cta__mobile-toggle__icon:before,
.mobile-cta__mobile-toggle__icon:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  border-top: 1px solid;
  border-radius: 1px;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.header__mobile-toggle__icon:before,
.mobile-cta__mobile-toggle__icon:before {
  top: 35%;
}
.header__mobile-toggle__icon:after,
.mobile-cta__mobile-toggle__icon:after {
  top: 65%;
}
html.is-mobile-menu-active .header__mobile-toggle__icon:after,
html.is-mobile-menu-active .mobile-cta__mobile-toggle__icon:after {
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}
html.is-mobile-menu-active .header__mobile-toggle__icon:before,
html.is-mobile-menu-active .mobile-cta__mobile-toggle__icon:before {
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
  transform: translate3d(-50%, -50%, 0) rotate(45deg);
}
@media screen and (min-width: 1025px) {
  .header__mobile-toggle,
  .mobile-cta__mobile-toggle {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .header__links,
  .mobile-cta__links {
    position: fixed;
    width: 100%;
    top: 60px;
    left: 0;
    -webkit-transform: unset;
    transform: unset;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: var(--space-1);
    color: var(--cr-white-1);
    background-color: var(--cr-black-1);
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }
  html:not(.is-mobile-menu-active) .header__links,
  html:not(.is-mobile-menu-active) .mobile-cta__links {
    opacity: 0;
    pointer-events: none;
  }
  .header__links ul,
  .mobile-cta__links ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    gap: 3px;
  }
  .header__links ul li a,
  .mobile-cta__links ul li a {
    display: block;
    padding: var(--space-1);
    text-align: center;
    border-radius: var(--border-radius-2);
    background-color: rgba(125, 125, 125, 0.1);
  }
}
@media screen and (max-width: 1024px) and (hover: hover) {
  .header__links ul li a:hover,
  .mobile-cta__links ul li a:hover {
    background-color: rgba(125, 125, 125, 0.2);
  }
}
@media screen and (max-width: 1024px) {
  .header__cta {
    display: none;
  }
}
.mobile-cta {
  width: 100%;
  top: unset;
  bottom: 0;
  border-top: 1px solid rgba(125, 125, 125, 0.2);
  border-bottom: unset;
}
.mobile-cta__cta {
  width: 100%;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media screen and (min-width: 1025px) {
  .mobile-cta {
    display: none;
  }
}
.footer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 40px var(--space-1);
  color: var(--cr-white-2);
  background: var(--cr-black-2);
  gap: var(--space-2);
}
.footer__logo {
  margin-right: auto;
}
.footer__logo svg {
  display: block;
  height: 40px;
  aspect-ratio: 1;
}
.footer__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.footer__social svg {
  width: 36px;
  height: 36px;
}
.footer__legal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
}
@media screen and (min-width: 601px) {
  .footer__social {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
  }
}
@media screen and (max-width: 600px) {
  .footer {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 20px var(--space-1);
    gap: 20px;
  }
  .footer__social {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: 100%;
    -webkit-box-flex: unset;
    -ms-flex: unset;
    flex: unset;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-bottom: 20px;
    border-bottom: 1px solid;
  }
  .footer__logo {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .footer__legal {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
}
.hero-section {
  position: sticky;
  margin: var(--space-2) 0;
  text-align: center;
}
.hero-section span {
  display: inline-block;
}
.hero-section__letter {
  --size: 20vw;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  margin-bottom: var(--space-1);
  gap: 2vw;
  color: var(--cr-black-1);
}
.hero-section__letter-a {
  margin-left: -1vw;
}
.hero-section__letter-c {
  margin-right: -0.5vw;
}
.hero-section__letter-c {
  aspect-ratio: 1;
  height: calc(var(--size) * 1.05);
}
.hero-section__letter-a svg,
.hero-section__letter-m svg,
.hero-section__letter-p svg {
  height: var(--size);
}
@media screen and (max-width: 600px) {
  .hero-section__letter {
    --size: 24vw;
  }
}
.intro-section {
  position: relative;
  overflow: hidden;
  min-height: clamp(420px, 80svh, 900px);
  margin: 0 var(--space-1);
  padding: var(--space-3) var(--space-2);
  color: var(--cr-white-1);
  background-color: var(--cr-cold-1);
  border-radius: var(--border-radius-3);
}
.intro-section__heading {
  position: relative;
  margin-bottom: var(--space-1);
}
.intro-section__paragraph {
  position: relative;
  text-wrap: pretty;
  max-width: 1360px;
  margin: 0 auto;
}
@media screen and (max-width: 1024px) {
  .intro-section {
    min-height: clamp(420px, 80svh, 900px);
  }
}
@media screen and (max-width: 600px) {
  .intro-section {
    min-height: clamp(420px, 50svh, 900px);
  }
}
.unique-section {
  --icon-size: 72px;
  margin: var(--space-3) var(--space-1);
  text-align: center;
}
.unique-section__heading {
  margin-bottom: var(--space-2);
}
.unique-section__cols {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: var(--space-1);
}
.unique-section__block {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 600px;
  overflow: hidden;
  padding: var(--space-2) var(--space-1);
  border-radius: var(--border-radius-3);
}
.unique-section__block h3 {
  position: relative;
}
.unique-section__block p {
  position: relative;
  max-width: 420px;
  margin: auto;
}
.unique-section__block:nth-child(1) {
  background-color: var(--cr-warm-1);
}
.unique-section__block:nth-child(2) {
  background-color: var(--cr-warm-2);
}
.unique-section__block:nth-child(3) {
  background-color: var(--cr-warm-3);
}
.unique-section__icon {
  position: relative;
  width: 100%;
  max-width: 240px;
  margin: 0 auto;
  aspect-ratio: 1;
}
@media screen and (max-width: 1024px) {
  .unique-section__cols {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .unique-section__block {
    min-height: unset;
  }
  .unique-section__block h3 br {
    display: none;
  }
}
@media screen and (max-width: 1024px) and (min-width: 601px) {
  .unique-section__block {
    display: grid;
    grid-template-areas: "a b" "a c";
    text-align: left;
    gap: 20px 40px;
  }
  .unique-section__block h3 {
    grid-area: b;
    align-self: end;
  }
  .unique-section__block p {
    grid-area: c;
    margin: 0;
  }
  .unique-section__icon {
    grid-area: a;
    width: 240px;
  }
}
@media screen and (max-width: 600px) {
  .unique-section__block {
    padding: var(--space-2) 25px;
  }
  .unique-section__block p {
    text-wrap: pretty;
  }
  .unique-section__icon {
    width: 200px;
  }
}
.investor-section {
  margin: var(--space-3) var(--space-1);
  text-align: center;
}
.investor-section__heading {
  margin-bottom: var(--space-2);
}
.investor-section__title {
  margin: var(--space-2) 0;
}
.investor-section__logos {
  padding: var(--space-2) 0;
  border-top: 2px solid;
  border-bottom: 2px solid;
  gap: 40px;
  margin-bottom: var(--s-section);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@-webkit-keyframes logoInactive {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-50%, 50%, 0);
    transform: translate3d(-50%, 50%, 0);
  }
}
@keyframes logoInactive {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-50%, 50%, 0);
    transform: translate3d(-50%, 50%, 0);
  }
}
@-webkit-keyframes logoActive {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}
@keyframes logoActive {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}
.investor-section__logos__logo-container {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 60px;
}
.investor-section__logos__logo {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
  max-width: 160px;
  margin: auto;
  pointer-events: none;
}
.investor-section__logos__logo img {
  -o-object-fit: contain;
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.investor-section__logos.investor-section__logos__secondary {
  padding: 0;
  border-top: 0;
  border-bottom: 0;
}
.investor-section__logos.investor-section__logos__secondary
  .investor-section__logos__logo-container {
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.investor-section__logos.investor-section__logos__secondary
  .investor-section__logos__logo-container[style*="--delay"] {
  opacity: 1;
}
.investor-section__logos.investor-section__logos__secondary
  .investor-section__logos__logo {
  -webkit-animation: 0.4s logoInactive both;
  animation: 0.4s logoInactive both;
  -webkit-animation-delay: calc(var(--delay) * 0.2);
  animation-delay: calc(var(--delay) * 0.2);
}
.investor-section__logos.investor-section__logos__secondary
  .investor-section__logos__logo.is-active {
  -webkit-animation: 0.4s logoActive both;
  animation: 0.4s logoActive both;
  -webkit-animation-delay: calc(var(--delay) * 0.2 + 0.4s);
  animation-delay: calc(var(--delay) * 0.2 + 0.4s);
}
@media screen and (max-width: 1024px) {
  .investor-section__logos {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .investor-section__logos__logo-container {
    width: 25%;
    -webkit-box-flex: unset;
    -ms-flex: unset;
    flex: unset;
  }
}
@media screen and (max-width: 600px) {
  .investor-section__logos__logo-container {
    width: 30%;
  }
  .investor-section__logos__logo-container.is-full-row-on-mobile {
    width: 100%;
  }
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 60px, 0);
    transform: translate3d(0, 60px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 60px, 0);
    transform: translate3d(0, 60px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translate3d(0, 120%, 0);
    transform: translate3d(0, 120%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideUp {
  0% {
    -webkit-transform: translate3d(0, 120%, 0);
    transform: translate3d(0, 120%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeScaleIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-50%, -50%, 0) scale(0.5);
    transform: translate3d(-50%, -50%, 0) scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
}
@keyframes fadeScaleIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-50%, -50%, 0) scale(0.5);
    transform: translate3d(-50%, -50%, 0) scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
}
[data-animate] h2,
[data-animate] h3,
[data-animate] h4,
[data-animate] h5,
[data-animate] h6,
[data-animate] p,
[data-animate] ul,
[data-animate] ol,
[data-animate] .shapes {
  opacity: 1;
}
h2[data-animate],
h3[data-animate],
h4[data-animate],
h5[data-animate],
h6[data-animate],
p[data-animate],
ul[data-animate],
ol[data-animate] {
  opacity: 1;
}
h2[data-animate="true"],
[data-animate="true"] h2,
h3[data-animate="true"],
[data-animate="true"] h3,
h4[data-animate="true"],
[data-animate="true"] h4,
h5[data-animate="true"],
[data-animate="true"] h5,
h6[data-animate="true"],
[data-animate="true"] h6,
p[data-animate="true"],
[data-animate="true"] p,
ul[data-animate="true"],
[data-animate="true"] ul,
ol[data-animate="true"],
[data-animate="true"] ol {
  -webkit-animation: 0.6s var(--delay, 0s) fadeInUp both;
  animation: 0.6s var(--delay, 0s) fadeInUp both;
}
[data-animate="true"] .shapes {
  -webkit-animation: 0.6s var(--delay, 0s) fadeScaleIn both;
  animation: 0.6s var(--delay, 0s) fadeScaleIn both;
}
.hero-section[data-animate] span {
  visibility: hidden;
}
.hero-section[data-animate][data-animate="true"] span {
  visibility: visible;
  -webkit-animation: 1s slideUp cubic-bezier(0.87, 0, 0.13, 1) both;
  animation: 1s slideUp cubic-bezier(0.87, 0, 0.13, 1) both;
}
.hero-section[data-animate][data-animate="true"] span:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.hero-section[data-animate][data-animate="true"] span:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.hero-section[data-animate][data-animate="true"] span:nth-child(4) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.shapes[data-animate] {
  opacity: 1;
}
.shapes[data-animate="true"] {
  -webkit-animation: 0.6s var(--delay, 0s) fadeScaleIn both;
  animation: 0.6s var(--delay, 0s) fadeScaleIn both;
}
