html,
body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
}

body.fontLoaded {
  font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

a {
  color: #7dd3fc;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

body {
  color: #e7e8e8;
  background-color: #131316;
}

label {
  font-family: Georgia, Times, 'Times New Roman', serif;
  line-height: 1.5em;
}

/* @media only screen and (min-width: 1728px){
  .container-fluid {
    max-width: 1728px;
    margin: auto;
  }
} */

.container-fluid {
  width: 100%;
}