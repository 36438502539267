.react-datepicker-wrapper,
.react-datepicker__input-container input {
  background-color: #1a1a1e;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  padding: 5.5px;
  width: 100%;
  max-width: 120px;
}
.dark-theme-datepicker{
    background: #1a1a1e;
    border-radius: 12px;
    border: 2px solid #e5e5e5;
    overflow: hidden;
    height: 42.5px;
    margin-left: 20px;
    display: flex;
}
.react-datepicker__month-container {
  background-color: #424242;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #fff
}

.react-datepicker__day--today {
  background-color: #424242;
  border-radius: 50%;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background-color: #6096ff;
  border-radius: 50%;
}

.react-datepicker__day--disabled {
  color: #aaa;
}

.react-datepicker__header {
  background-color: #1a1a1e;
  border-bottom: none;
  color: #fff;
}

.react-datepicker__triangle {
  border-bottom-color: #212121;
}

.react-datepicker__year-dropdown-container,
.react-datepicker__month-dropdown-container {
  background-color: #424242;
  border: 1px solid #212121;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
  background-color: #424242;
  color: #fff;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover {
  background-color: #ff4081;
}
.react-datepicker-ignore-onclickoutside:focus-visible{
border: none !important;
outline: none !important;
}

.react-datepicker__current-month{
    color: white;
}

 .react-datepicker__input-container input {
background-color: #1a1a1e; 
}